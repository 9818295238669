.main-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.intro-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: auto;
  padding-top: 2.5em;
  padding-left: 10px;
  padding-right: 10px;
}

.animation-container {
  width: 500px;
  height: 500px;
}

.flex-child {
  flex: 1;
  flex-basis: 0;
  min-width: 0;
}
.main-header {
  margin: auto;
  color: white;
  justify-content: center;
  align-items: center;
}
.main-body {
  color: white;
  justify-content: center;
  text-align: center;
  margin: auto;
}
@media (max-width: 767px) {
  .main-container {
    flex-direction: column;
  }
}
.profile-picture-container {
  height: auto;
  width: auto;
  display: flex;
  justify-content: center;
  padding-top: 5%;
}
.no-bull {
  list-style-type: none;
}
#fade {
  animation: fadein 4s;
}
.fade-in-image {
  animation: fadeIn 4s;
  -webkit-animation: fadeIn 4s;
  -moz-animation: fadeIn 4s;
  -o-animation: fadeIn 4s;
  -ms-animation: fadeIn 4s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
