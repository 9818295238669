.profile-picture-container {
  height: auto;
  width: auto;
  display: flex;
  justify-content: center;
  padding-top: 5%;
}
.profile-picture {
  border-radius: 50%;
  border: 7pt solid white;
  height: auto;
  width: auto;
  max-width: 100%;
}
.flex-child {
  flex: 1;
  flex-basis: 0;
  min-width: 0;
}
