.navbar-override-color {
  background-color: #03051e !important;
  width: 100vw !important;
}

.navbar-item-bold {
  font-size: x-large;
  background-color: #978d58 !important;
}

.badge-notify {
  background-color: #eae1e1 !important;
  font-size: small !important;
  color: black !important;
  top: auto !important;
}
.favicon-home {
  padding-left: 20%;
}
