.contact-me-main {
  text-align: center;
  color: white;
}
.contact-me-header-container {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact-me-header {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 2em;
}

.contact-me-line-item {
  width: auto !important;
}

.input-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.input-item {
  margin: 0.5em;
}
