:root {
  --main-color: #0b5269;
  --secondary-color: #978d58;
  --secondary-color-2: #eae1e1;
  --secondary-color-3: #00a4bd;
  --gradient-color-1: #453c67;
  --gradient-color-2: #6d67e4;
  --gradient-color-3: #46c2cb;
  --gradient-color-4: #f2f7a1;
}
#info-row-1 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(200px, auto);
}
.main-title {
  animation: change-color 5s;
}
.infowidget-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: 40px auto 40px auto;
  height: auto;
  width: 70%;
  padding: 2em;
  color: white;
}

.infowidget-child {
  display: flex;
}
.infowidget-body {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding-top: 2em;
  flex-direction: column;
}
.infowidget-border {
  padding: 2em;
  border-style: solid;
  border-radius: 12px;
  box-shadow: 4px 4px var(--secondary-color-3), 8px 8px var(--secondary-color),
    12px 12px var(--secondary-color-2);
}

.gradient-border {
  --border-width: 3px;

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  height: auto;
  color: white;
  background-color: #03051e;
  border-radius: var(--border-width);

  &::after {
    position: absolute;
    content: "";
    top: calc(-1 * var(--border-width));
    left: calc(-1 * var(--border-width));
    z-index: -1;
    width: calc(100% + var(--border-width) * 2);
    height: calc(100% + var(--border-width) * 2);
    background: linear-gradient(
      60deg,
      var(--gradient-color-1),
      var(--gradient-color-2),
      var(--gradient-color-3),
      var(--gradient-color-4)
    );
    background-size: 300% 300%;
    background-position: 0 50%;
    border-radius: calc(2 * var(--border-width));
    animation: moveGradient 4s alternate infinite;
  }
}

.gradient-border:hover {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  height: auto;
  color: white;
  background-color: #03051e;
  border-radius: var(--border-width);
  border-style: solid;
  border-color: white;
}

@keyframes moveGradient {
  50% {
    background-position: 100% 50%;
  }
}
