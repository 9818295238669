.card-container {
  display: flex;
  width: 90%;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-items: stretch;
}

.card-individual {
  padding: 20px;
}
.card-button {
  padding-top: 20px;
  padding-bottom: 10px;
}

.page {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 100px;
}
.project-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: auto;
}
.section-title {
  text-align: center;
}

.animation {
  height: 750px;
  width: 750px;
}

.under-construction-heading {
  color: white;
}
