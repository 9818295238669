.page {
  display: flex;
  flex-direction: row;
}

.experience-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
  margin-right: 50px;
}
.experience-card {
  max-width: 50%;
  margin: 5px;
}

.experience-card ul {
  padding: 0;
  list-style: circle;
  text-align: left;
}

.experience-card li {
  margin-bottom: 10px;
}

.certification-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
}

.certification-card {
  border: 1px solid white;
  width: 50px auto;
  margin-bottom: 50px;
  padding: 20px;
  text-align: center;
}

.section-title {
  margin-bottom: 50px;
  color: white;
  padding: 20px;
  text-align: center;
  border-style: solid;
}

.experience-btn {
  width: 600px;
}

/* When the screen size becomes too small, change the display to a column */
@media (max-width: 800px) {
  .page {
    flex-direction: column;
    width: auto;
    align-items: center;
  }
  .experience-card,
  .certification-card,
  .certification-section,
  .experience-section {
    max-width: 100%;
    width: 100%;
  }
  .experience-section {
    margin: auto;
  }
}
